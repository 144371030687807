import { PublicStatusType } from '@dltru/dfa-models'
import { FC, useContext, useEffect, useState } from 'react'

import { Form, FormInstance, WrappedInput, WrappedSelect } from '../../../components/Form'
import { commonValidators } from '../../../utils'
import { AddressDetails } from '../addressComponent'
import { publicStatusOptions } from '../resources'

interface IPublicPersonStepProps {
    uneditable: boolean
    form: FormInstance<any>
    name: string
    initialValues: any
}

export const PublicPerson: FC<IPublicPersonStepProps> = ({
    uneditable,
    form,
    name,
    initialValues,
}) => {
    const [country, setCountry] = useState('ru')
    const [status, setStatus] = useState<PublicStatusType>()

    useEffect(() => {
        if (initialValues.public_official) {
            if (initialValues.public_official.am_i) {
                setStatus(PublicStatusType.public)
                return
            }

            if (initialValues.public_official.related) {
                setStatus(PublicStatusType.related)
                return
            }

            setStatus(PublicStatusType.notPublic)
        }
    }, [initialValues])

    const setAddressValue = (value: string) => {
        form.setFieldsValue({
            'public_official.job.official_address': value,
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            'public_official.job.country': 'ru',
        })
    }, [])

    return (
        <>
            <div className="formRow">
                <WrappedSelect
                    onChange={(val) => {
                        setStatus(val as PublicStatusType)
                    }}
                    defaultActiveFirstOption
                    uneditable={uneditable}
                    required
                    label="Сведения о публичном должностном лице"
                    className="formComponentItem--xl"
                    name={'public_official.status'}
                    options={publicStatusOptions}
                />
            </div>
            {(status === PublicStatusType.public || status === PublicStatusType.related) && (
                <>
                    {status === PublicStatusType.related && (
                        <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                            <WrappedInput
                                className="formComponentItem--xl"
                                label="Степень родства"
                                name={'public_official.relation'}
                                placeholder="Например, дочь"
                                uneditable={uneditable}
                                required
                                rules={[{ validator: commonValidators.stringWithSpaces() }]}
                            />
                        </div>
                    )}

                    <div className="formRow-vertical ant-form-vertical" style={{ marginLeft: 250 }}>
                        <WrappedSelect
                            onChange={(val) => {
                                setCountry(val as string)
                            }}
                            initialValue={country}
                            uneditable={uneditable}
                            required
                            disabled
                            label="Государство, резидентом которого является публичное должностное лицо"
                            className="formComponentItem"
                            name={'public_official.job.country'}
                            options={[
                                {
                                    value: 'ru',
                                    label: 'Россия',
                                },
                            ]}
                        />
                    </div>

                    <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                        <WrappedInput
                            className="formComponentItem--xl"
                            label="Наименование организации"
                            name={'public_official.job.title'}
                            placeholder=""
                            uneditable={uneditable}
                            maxLength={200}
                            required
                        />
                    </div>
                    {
                        <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                            <WrappedInput
                                label="Юридический адрес организации"
                                className="formComponentItem--xl"
                                name={'public_official.job.official_address'}
                                placeholder="Введите юридический адрес организации"
                                uneditable={uneditable}
                                required
                                disabled
                            />
                        </div>
                    }
                    {!uneditable && (
                        <AddressDetails
                            uneditable={uneditable}
                            prefix="public_official"
                            name="official_address"
                            marginLeft={250}
                            setAddressValue={setAddressValue}
                        />
                    )}
                    <div className="formRow ant-form-vertical" style={{ marginLeft: 250 }}>
                        <WrappedInput
                            className="formComponentItem--xl"
                            label="Должность публичного должностного лица"
                            name={'public_official.job.position_title'}
                            placeholder=""
                            uneditable={uneditable}
                            required
                            rules={[{ validator: commonValidators.stringWithSpacesAndDash() }]}
                        />
                    </div>
                </>
            )}
        </>
    )
}
